<template>
  <div class="wxpay">
    <pay :courseVO="courseVO" v-on:on-pay="onPay" :payReady="payReady"></pay>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
const QUERY_INTERVAL = 5000;
const PAY_URL = process.env.VUE_APP_PAY;

import pay from "@/components/pay.vue";
import csWidget from "@/components/csWidget.vue";
import { Notify } from "vant";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";

export default {
  components: { pay, csWidget },
  data() {
    return {
      courseId: 0,
      orderId: 0,
      courseInfo: {},
      codeUrl: "",
      courseVO: {
        course: {
          scheduleCnt: 0,
          saleCount: 0,
          showPrice: 0,
          truePrice: 0,
          tags: [],
        },
        scheduleList: [],
        ownThisCourse: 0,
        teacherList: [],
      },
      clock: null,
      showCsWidget: false,
      csTitle: "参数出错，请联系客服",
      amount: 0,
      payReady: true,
    };
  },
  methods: {
    onPay(orderDiscountVO) {
      if (!this.courseId) {
        this.showCsWidget = true;
        return;
      }
      orderApi.create(this.courseId, orderDiscountVO.referPhone, orderDiscountVO.reducePriceLong).then((ret) => {
        const { code, msg, data } = ret;
        if (code !== 0) {
          alert(msg);
        }
        this.amount = this.courseInfo.truePrice;
        if (orderDiscountVO && orderDiscountVO.totalFee > 0) {
          this.amount = orderDiscountVO.totalFee;
        }
        this.orderId = data.id;
        console.log(this.orderId);
        payApi
          .startPayWithoutOpenId("WXPAY_MWEB", this.orderId, this.amount)
          .then((ret) => {
            if (ret.code == 0 && ret.data) {
              let url = "";
              if (process.env.NODE_ENV == "development") {
                url = encodeURIComponent("http://ckw.kaiweitalk.cn/pay/payWaiting?id=" + this.orderId);
              } else {
                url = encodeURIComponent("http://aida101.com/pay/payWaiting?id=" + this.orderId);
              }
              // const url = encodeURIComponent(PAY_URL + this.orderId);
              const payUrl = ret.data.mwebUrl + "&redirect_url=" + url;
              window.location.href = payUrl;
            } else {
              this.showCsWidget = true;
            }
          })
          .catch((err) => {
            this.showCsWidget = true;
          });
      });
    },
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      if (this.courseId <= 0) {
        this.showCsWidget = true;
      }
      return this.courseId;
    },
    getCourse(courseId) {
      courseApi.courseInfo(courseId).then((ret) => {
        if (ret && ret.code == 0 && ret.data) {
          this.courseInfo = ret.data.course;
          this.courseVO = ret.data;
          console.log("courseVO...", this.courseVO);
        } else {
          this.showCsWidget = true;
        }
      });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    const courseId = this.getId();
    this.courseId = courseId;
    this.listenOnCloseCsWidget();
    this.getCourse(courseId);
    console.log(process.env);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.wxpay {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
}
</style>
